import { Menu, Transition } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment, useState } from 'react';
import { t } from '../../../generated/i18n';
import { topNavigation } from '../../../generated/navigation';
import logoSvg from '../../../img/edvin.svg';
import { IS_DEFAULT_CHANNEL } from '../../../lib/config';
import { trackEvent } from '../../../lib/tracking';
import { CartIcon } from '../../cart/cart-icon';
import { SearchInput } from '../../filter/search-input';
import { WishlistIcon } from '../../wishlist/whishlist-icon';
import { Banner } from '../banner';
import { MaxContainer } from '../container';
import { Menu as MobileMenu } from './menu';

const navItemClassName =
  ' whitespace-nowrap font-bold uppercase decoration-2 underline-offset-4 hover:text-green hover:underline focus:text-gray-700 focus:outline-none active:underline  transition duration-200';

const NavItem = React.forwardRef(
  // @ts-ignore
  ({ name, url, currentPath, className = '' }, ref) => (
    <Link
      href={url}
      prefetch={false}
      ref={ref}
      className={`${className} ${navItemClassName} ${
        currentPath.startsWith(url) ? 'underline' : ''
      }`}
    >
      {name}
    </Link>
  ),
);

NavItem.displayName = 'NavItem';

const MenuItemWithSubmenu = ({
  label,
  subLinks,
  currentPath,
  topNavigationSlice = [],
}) => {
  return (
    <div className='relative hidden text-left lg:inline-block'>
      <Menu as={React.Fragment}>
        <Menu.Button className={navItemClassName}>{label}</Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute -left-5 flex origin-top-right flex-col gap-3 bg-white px-5 py-4 shadow-lg focus:outline-none'>
            {topNavigationSlice.map((m, index) => (
              <Menu.Item key={`nav-${index}`}>
                {() => (
                  <NavItem
                    // @ts-ignore
                    name={m.text}
                    currentPath={currentPath}
                    url={m.link.href}
                    className='hidden lg:block xl:hidden'
                  />
                )}
              </Menu.Item>
            ))}
            {subLinks.map((m, index) => (
              <Menu.Item key={`sub-${index}`}>
                {() => (
                  <NavItem
                    // @ts-ignore
                    name={m.text}
                    currentPath={currentPath}
                    url={m.link.href}
                  />
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const Search = ({ className = '' }) => {
  const [showSearch, setShowSearch] = useState(false);
  const toggleShowSearch = () => setShowSearch(!showSearch);

  return (
    <div
      className={`${className} hidden relative -mr-[10px] sm:flex w-full items-center justify-end`}
    >
      {IS_DEFAULT_CHANNEL && (
        <a
          href='https://gastro.edvinweine.ch/'
          className={`${
            showSearch ? 'hidden' : ''
          } link font-bold uppercase mr-12 whitespace-nowrap hidden sm:block`}
        >
          {t('nav-b2b-login')}
        </a>
      )}

      <div className='flex w-full items-center justify-end absolute'>
        <Transition
          show={showSearch}
          enter='transition-width duration-150'
          enterFrom='w-[330px]'
          enterTo='w-full'
          leave='transition-width duration-150'
          leaveFrom='w-full'
          leaveTo='w-[330px]'
          className='w-full'
        >
          <SearchInput
            autoFocus={true}
            className='absolute right-0 top-0'
            placeholder={t('Suchen')}
            inputClassName={`rounded-md bg-white text-[13px] h-[32px] pl-[0.6rem] border-0.6 border focus:ring-0.6 block w-full py-1.5 pl-10 text-gray-900 border placeholder:text-gray-400 focus:outline-none`}
          />
        </Transition>

        <a
          className={`${
            showSearch ? '' : 'hidden'
          } absolute right-[35px] top-[10px] cursor-pointer`}
          onClick={toggleShowSearch}
        >
          <svg width='11' height='11' viewBox='0 0 11 11' fill='none'>
            <path d='M1 10L10 1' stroke='#231F20' strokeMiterlimit='10' />
            <path d='M10 10L1 1' stroke='#231F20' strokeMiterlimit='10' />
          </svg>
        </a>

        <a
          className={`absolute mr-[10px] cursor-pointer `}
          onClick={toggleShowSearch}
        >
          <svg width='17' height='17' viewBox='0 0 17 17' fill='none'>
            <path
              d='M16.8783 15.9908L11.8506 10.9523C12.9494 9.62867 13.4951 7.93308 13.3748 6.21839C13.2544 4.50335 12.477 2.90084 11.204 1.74289C9.93101 0.585295 8.26079 -0.038134 6.53943 0.00180717C4.81808 0.0420956 3.17881 0.742629 1.9611 1.95823C0.743736 3.17383 0.0421865 4.81107 0.00183954 6.52993C-0.0385074 8.24844 0.586174 9.91659 1.7451 11.1878C2.90438 12.4589 4.50956 13.2352 6.22709 13.3554C7.94462 13.4755 9.64232 12.9306 10.9679 11.8334L15.9956 16.8538C16.1521 16.9879 16.3663 17.0334 16.5639 16.9751C16.7618 16.9167 16.9166 16.7622 16.975 16.5645C17.0335 16.3669 16.9879 16.1533 16.8536 15.9967L16.878 15.9904L16.8783 15.9908ZM1.22894 6.69768C1.22894 5.24695 1.80597 3.85561 2.83343 2.82964C3.86088 1.80402 5.25424 1.22748 6.70708 1.22748C8.15992 1.22748 9.55328 1.80367 10.5807 2.82964C11.6082 3.85561 12.1852 5.24695 12.1852 6.69768C12.1852 8.14841 11.6082 9.53976 10.5807 10.5657C9.55328 11.5917 8.15992 12.1679 6.70708 12.1679C5.25424 12.1679 3.86088 11.5917 2.83343 10.5657C1.80597 9.53976 1.22894 8.14841 1.22894 6.69768Z'
              fill='black'
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

const BaseNav = () => {
  const router = useRouter();
  const currentPath = router.asPath;
  const [showMenu, setShowMenu] = useState(false);
  const toggleShowMenu = () => {
    trackEvent({
      category: 'menu',
      action: 'toggle',
      label: `${showMenu}`,
    });
    setShowMenu(!showMenu);
  };

  return (
    <>
      <nav
        className={`fixed left-0 right-0 top-0 z-30 bg-white drop-shadow-lg print:hidden`}
      >
        <Banner />
        <MaxContainer>
          <div className='flex h-[57px] items-center justify-between gap-5 lg:h-[74px] lg:gap-6'>
            <Link
              href='/'
              prefetch={false}
              aria-label={t('home')}
              className='w-[56px] lg:mr-4 lg:w-[62px]'
            >
              <img
                alt=''
                src={logoSvg}
                className='mx-auto min-h-[40px] w-[56px] max-w-none lg:w-[62px]'
              />
            </Link>

            {topNavigation.map((item, index) => {
              if (item.subLinks && item.subLinks.length > 0) {
                return (
                  <MenuItemWithSubmenu
                    key={index}
                    label={item.text}
                    subLinks={item.subLinks}
                    currentPath={currentPath}
                    topNavigationSlice={topNavigation.slice(-3, -1)}
                  />
                );
              }

              return (
                <NavItem
                  className={
                    index > topNavigation.length - 5
                      ? 'hidden xl:block'
                      : 'hidden lg:block'
                  }
                  key={index}
                  url={item.link.href}
                  name={item.text}
                  currentPath={currentPath}
                />
              );
            })}

            <div className='flex-grow block'></div>

            <Search />

            <Link href='/merkliste' prefetch={false}>
              <WishlistIcon />
            </Link>

            <Link href='/profile' prefetch={false}>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 16.0937C1 12.9474 3.62857 10.3968 9 10.3968C14.3714 10.3968 17 12.9474 17 16.0937C17 16.5942 16.6348 17 16.1843 17H1.81569C1.3652 17 1 16.5942 1 16.0937Z'
                  stroke='black'
                  strokeWidth='1.25'
                />
                <path
                  d='M12 4C12 5.65685 10.6569 7 9 7C7.34314 7 6 5.65685 6 4C6 2.34315 7.34314 1 9 1C10.6569 1 12 2.34315 12 4Z'
                  stroke='black'
                  strokeWidth='1.25'
                />
              </svg>
            </Link>

            <CartIcon />

            <button
              onClick={toggleShowMenu}
              className='ml-3 focus:outline-none lg:hidden'
              aria-label={t('menu')}
            >
              <svg
                width='30'
                height='19'
                viewBox='0 0 30 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <line y1='0.5' x2='30' y2='0.5' stroke='#6F6F70' />
                <line y1='9.5' x2='30' y2='9.5' stroke='#6F6F70' />
                <line y1='18.5' x2='30' y2='18.5' stroke='#6F6F70' />
              </svg>
            </button>
          </div>
        </MaxContainer>
      </nav>

      <MobileMenu toggleShowMenu={toggleShowMenu} showMenu={showMenu} />
    </>
  );
};

export const Nav = React.memo(BaseNav);
